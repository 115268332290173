.colored-bg {
    background: linear-gradient(97.92deg, #589DAD 1.23%, #6BADBC 67.32%, #7CB3BF 96.95%);
}

.signup-w-3-12-5 {
    width: 28%;
}

.steps-row .before-after::before {
    content: '';
    position: absolute;
    left: 0%;
    transition: .4s all;
    top: 30%;
    width: 250%;
    height: 3px;
    background: var(--callToActionTextColor);
    transform: translate(-70%, 0%);
    z-index: -1;
}

.steps-row.match .before-after::before {
    content: '';
    position: absolute;
    left: 0%;
    transition: .4s all;
    top: 30%;
    width: 350%;
    height: 3px;
    background: var(--callToActionTextColor);
    transform: translate(-90%, 0%);
    z-index: -1;
}

.steps-row.match .before-after::after {
    content: '';
    position: absolute;
    right: 0%;
    top: 30%;
    transition: .4s all;
    width: 350%;
    height: 3px;
    background: var(--callToActionTextColor);
    transform: translate(90%, 0%);
    z-index: -1;
}

.rounded-3xl-5 {
    border-radius: 25px;
}

.steps-row .before-after.step-two::before {
    background-color: var(--primaryButtonColor) !important;
}

.steps-row .before-after.step-three::before,
.steps-row .before-after.step-three::after {
    background-color: var(--primaryButtonColor) !important;
}


.steps-row .before-after::after {
    content: '';
    position: absolute;
    right: 0%;
    top: 30%;
    transition: .4s all;
    width: 250%;
    height: 3px;
    background: var(--callToActionTextColor);
    transform: translate(80%, 0%);
    z-index: -1;
}

@media only screen and (max-width:1450px) and (min-width:1201px) {
    .signup-w-3-12-5 {
        width: 45% !important;
    }
}

@media only screen and (min-width:1440px) {
    .signup-w-3-12-5 {
        width: 40%;
    }

    .hr-bottom {
        width: 50% !important;
    }
}

@media only screen and (max-width:820px) and (min-width:670px) {
    .signup-w-3-12-5 {
        width: 75% !important;
    }
}

@media only screen and (max-width:670px) {
    .signup-w-3-12-5 {
        width: 100% !important;
        padding: 0px 25px !important;
    }
}

@media only screen and (max-width:1320px) and (min-width:1200px) {
    .steps-row .before-after::before {
        width: 150% !important;
    }
}

@media only screen and (max-width:1201px) and (min-width:820px) {
    .signup-w-3-12-5 {
        width: 60% !important;
    }
}

@media only screen and (max-width:1201px) and (min-width:1140px) {
    .steps-row .before-after::before {
        width: 220% !important;
        transform: translate(-80%, 0%) !important;
    }

    .steps-row .before-after::after {
        width: 250% !important;
        transform: translate(80%, 0%) !important;
    }
}

@media only screen and (max-width:1140px) and (min-width:1150px) {
    .steps-row .before-after::before {
        width: 200% !important;
        transform: translate(-70%, 0%) !important;
    }
}

@media only screen and (max-width:990px) and (min-width:910px) {
    .steps-row .before-after::before {
        transform: translate(-60%, 0%) !important;
    }
}

@media only screen and (max-width:910px) and (min-width:550px) {
    .steps-row .before-after::before {
        transform: translate(-50%, 0%) !important;
        width: 200% !important;
    }

    .steps-row .before-after::after {
        transform: translate(70%, 0%) !important;
        /* width: 200% !important; */
    }
}

@media only screen and (max-width:550px) and (min-width:460px) {
    .steps-row .before-after::before {
        transform: translate(-35%, 0%) !important;
    }

    .steps-row .before-after::after {
        transform: translate(50%, 0%) !important;
    }
}

@media only screen and (max-width:460px) {
    .steps-row .before-after::before {
        transform: translate(-15%, 0%) !important;
    }

    .steps-row .before-after::after {
        transform: translate(30%, 0%) !important;
    }
}

@media only screen and (max-width:430px) {

    .steps-row .first,
    .steps-row .second,
    .steps-row .third {
        width: 120px;
    }

    .steps-row .before-after::before,
    .steps-row .before-after::after {
        top: 20% !important;
    }
}