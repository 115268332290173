@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(./assets/css/home_page.css);
@import url(./assets/css/login_page.css);
@import url(./assets/css/signup_page.css);

html,
body,
body * {
  padding: 0;
  scroll-behavior: smooth;
  /* background-color: #f3f4f6 !important; */
  margin: 0;
  font-family: "Inter", sans-serif;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.loader-spinner {
  animation: rotation 1s infinite linear;
}

:root {
  --secondaryHeadingColor: #808080;
  --primaryHeadingColor: #143975;
  --primaryButtonColor: #FFB538;
  --callToActionBgColor: #579CAC;
  --callToActionTextColor: #54A8C4;
  --uploaderLogoColor: #C4C4C4;
  --tagsColor: #E1E1E1;

}

.tags-color {
  background-color: var(--tagsColor);
}

.uploader-bg-color {
  background-color: var(--uploaderLogoColor);
}

@font-face {
  font-family: MuseoSans700;
  src: url('./assets/fonts/museo_sans_ssv/MuseoSans700/MuseoSans_700.otf');
}

@font-face {
  font-family: MuseoSans300;
  src: url('./assets/fonts/museo_sans_ssv/MuseoSans300/MuseoSans-300.otf');
}

@font-face {
  font-family: MuseoSans500;
  src: url('./assets/fonts/museo_sans_ssv/MuseoSans500/MuseoSans_500.otf');
}

a {
  cursor: pointer;
}

.call-to-action-text-color {
  color: var(--callToActionTextColor);
}

.steps-main-border-color {
  border-color: var(--callToActionTextColor);
}

.google-btn-bg-color {
  background-color: var(--callToActionTextColor);
}

.main-container {
  max-width: 1800px;
  padding: 0px 25px;
  width: 100%;
  margin: 0 auto;
  display: block;
}

.services-card {
  box-shadow: 2px 4px 5px 3px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
}

.country-card {
  box-shadow: 2px 4px 5px 3px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  min-height: 350px;
}

.margin-left {
  margin-left: -100px;
}

.footer-main-container {
  max-width: 1800px;
  padding: 0px 25px;
  width: 100%;
  margin: 0 auto;
  display: block;
}

.inner-container {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  display: block;
}

.inner-main-container {
  max-width: 1180px;
  width: 100%;
  padding: 0px 25px;
  margin: 0 auto;
  display: block;
}

input::placeholder,
textarea::placeholder {
  color: var(--secondaryHeadingColor);
  opacity: 1 !important;
}

.primary-heading-color {
  color: var(--primaryHeadingColor);
}

.secondary-heading-color {
  color: var(--secondaryHeadingColor);
}

.primary-bg-color {
  background-color: var(--primaryButtonColor);
}

.footer-main-container hr {
  border-color: #BDBDBD !important;
}

h1 {
  font-size: 70px;
  line-height: 84px;
}

h2 {
  font-size: 45px;
  line-height: 54px;
}

h3 {
  font-size: 35px;
  line-height: 42px;
}

h4 {
  font-size: 25px;
  line-height: 30px;
}

h5 {
  font-size: 20px;
  line-height: 24px;
}

h6 {
  font-size: 18px;
  line-height: 22px;
}

.text-6-5-xl {
  font-size: 55px;
  line-height: 66px;
}

.font-family-500 {
  font-family: MuseoSans500;
}

.font-family-300 {
  font-family: MuseoSans300;
}

.font-family-700 {
  font-family: MuseoSans700;
}

.steps {
  border-width: 3px;
  border-style: solid;
}

.secondary-border-color {
  border-color: var(--primaryButtonColor);
}

.secondary-link-color {
  color: var(--primaryButtonColor);
}

.call-to-action-color {
  color: var(--callToActionBgColor);
}

.call-to-action-border-color {
  border-color: var(--callToActionBgColor);
}

.px-22 {
  padding-left: 90px;
  padding-right: 90px;
}

.header .absolute {
  width: 96%;
  left: 2%;
}

@media only screen and (max-width:1050px) {
  .table-svgs svg {
    width: 30px !important;
  }

  .margin-left {
    margin-left: -50px !important;
  }

  .home-banner h1 {
    font-size: 40px !important;
    line-height: 48px !important;
  }

  h1,
  h2 {
    font-size: 30px !important;
    line-height: 36px !important;
  }

  h3 {
    font-size: 27px !important;
    line-height: 32px !important;
  }

  h4 {
    font-size: 25px !important;
    line-height: 30px !important;
  }

  h5 {
    font-size: 20px !important;
    line-height: 24px !important;
  }

  h6 {
    font-size: 18px !important;
    line-height: 22px !important;
  }
}

@media only screen and (max-width:850px) {
  .margin-left {
    margin-left: 0px !important;
  }

  .country-card {
    min-height: unset !important;
  }

  .react-responsive-modal-modal {
    min-width: auto !important;
    width: 90% !important;
    margin: 5% !important;
  }

}

@media only screen and (max-width:600px) {
  .country-banner {
    min-height: 770px;
    object-fit: cover;
  }
}

.counter-card {
  width: 32%;
  min-width: 120px;
}

@media only screen and (max-width:1040px) and (min-width:740px) {
  .counter-card {
    width: 48% !important;
  }
}

@media only screen and (max-width:740px) {
  .counter-card {
    width: 100% !important;
  }
}

.toggle-checkbox {
  top: 50%;
  background: #F32131;
  transition: .4s all;
  left: 2px;
  transform: translate(0%, -50%);
}

.toggle-checkbox:checked {
  @apply: right-0 border-white;
  right: 2px;
  border-color: #07944F;
  background-color: #07944F;
  left: unset !important;
}

.toggle-checkbox:checked+.toggle-label {
  @apply: bg-white;
  background-color: #2c828a;
}

.checkbox-custom-toggle .toggle-checkbox:checked+.toggle-label {
  background-color: transparent;
}

.ReactTags__tags.react-tags-wrapper,
.ReactTags__tagInput,
.ReactTags__tagInputField {
  width: 100%;
  border: none;
}

.ReactTags__tagInputField,
.ReactTags__tagInputField:hover,
.ReactTags__tagInputField:focus,
.ReactTags__tagInputField:focus-visible,
.ReactTags__tagInputField:focus-within,
.ReactTags__tagInputField:active {
  min-height: 44px;
  padding: 8px 32px;
  font-size: 18px;
  font-weight: 600;
  color: var(--secondaryHeadingColor);
  border-radius: 50px;
  border: 1px solid #BDBDBD !important;
  outline: none;
}

.error-borders .ReactTags__tagInputField,
.error-borders .ReactTags__tagInputField:hover,
.error-borders .ReactTags__tagInputField:focus,
.error-borders .ReactTags__tagInputField:focus-visible,
.error-borders .ReactTags__tagInputField:focus-within,
.error-borders .ReactTags__tagInputField:active {
  border: 1px solid rgb(239 68 68) !important;
  color: rgb(220 38 38) !important;
  font-family: MuseoSans500 !important;
  font-weight: 300 !important;
}

.ReactTags__tagInputField,
.ReactTags__tagInputField:hover,
.ReactTags__tagInputField:focus,
.ReactTags__tagInputField:focus-visible,
.ReactTags__tagInputField:focus-within,
.ReactTags__tagInputField:active {
  color: #808080 !important;
  font-family: MuseoSans500 !important;
  font-weight: 300 !important;
}

.ReactTags__suggestions {
  /* background-color: #BDBDBD; */
  border: 1px solid #BDBDBD;
  border-radius: 25px;
  margin-top: 10px;
  padding: 10px;
  max-height: 200px;
  overflow: auto;
}

.ReactTags__suggestions span {
  display: block;
  /* margin: 5px 0; */
  padding: 8px 5px;
  transition: .4s all;
  cursor: pointer;
  border-bottom: 1px solid #f1eeee;
  width: 100%;
}

.ReactTags__suggestions span:hover {
  display: block;
  /* margin: 5px 0; */
  padding: 8px 5px;
  transition: .4s all;
  cursor: pointer;
  background-color: #f1eeee;
  border-bottom: 1px solid #f1eeee;
  width: 100%;
}

.ReactTags__suggestions>* {
  padding: 2px 0;
  color: var(--secondaryHeadingColor);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.ReactTags__selected {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  /* margin-bottom: 10px; */
}

.tag-wrapper.ReactTags__tag {
  margin-bottom: 10px;
}

.ReactTags__selected span:hover {
  background: var(--secondaryHeadingColor) !important;
  border-color: var(--secondaryHeadingColor) !important;
}

.ReactTags__selected span {
  background: #589DAD;
  min-height: 30px;
  cursor: pointer !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  border: 1px solid #589DAD;
  border-radius: 6px;
  color: rgba(255, 255, 255, .9);
  font-size: 14px;
  font-weight: 600;
  transition: .3s all;
}

.ReactTags__selected span .ReactTags__remove {
  color: rgba(255, 255, 255, .8);
  cursor: pointer;
  margin-left: 5px;
}


::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
  background-color: #5a5a5a;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 50px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: rgb(237, 237, 237);
}

iframe::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
  background-color: #5a5a5a;
}

iframe::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}

iframe::-webkit-scrollbar-thumb {
  border-radius: 50px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: rgb(237, 237, 237);
}

.react-responsive-modal-modal {
  min-width: 90%;
  height: 95%;
}

iframe {
  height: 90vh !important;
}

.service-card {
  box-shadow: 2px 4px 5px 3px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}

.service-grid {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}


@media only screen and (max-width:1024px) {
  .service-grid {
    background-image: linear-gradient(176.65deg, #FFFFFF 5.52%, #7EC0CF 30.33%, #61A7B7 53.12%, #62A3B2 79.16%, #7CB3BF 94.79%) !important;
  }
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: var(--primaryButtonColor) !important;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: white !important;
}

.editorClassName.rdw-editor-main {
  border: 1px solid rgba(0, 0, 0, .07);
  padding: 0px 10px;
}

select {
  appearance: none;
}

.test-custom-grid {
  grid-template-columns: 70% 25%;
}

.test-inner-grid {
  grid-template-columns: 25% 5% 70%;
}

.before-color {
  height: 100%;
  width: 8px;
  border-radius: 50px;
}

.cv-color {
  background-color: #FFB538;
}

.test-color {
  background-color: #47BC52;
}

.before-after-test {
  position: relative;
}

.before-after-test::after {
  position: absolute;
  top: 0;
  right: -5%;
  height: 100%;
  width: 5px;
  background-color: #BDBDBD;
  content: '';
  border-radius: 50px;
}